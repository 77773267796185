<template>
  <b-card>
    <b-card-header>
      <div class="text-primary h2">
        <feather-icon
          icon="StarIcon"
          size="25"
          class="mr-0 mr-sm-50"
          variant="primary"
        />
        <span class="d-none d-sm-inline">{{$t('actions.modals.annull.title')}}</span>
      </div>
    </b-card-header>
    <b-card-body>
    
      <validation-observer 
        ref="elementForm"
        #default="{ invalid }"
        >
        <b-row>
          <b-col>
            <span>{{$t('arl_codes.object.annull_body')}}</span>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
                label-for="service_observations"
              >
              
              <feather-icon
                  icon="StarIcon"
                  class="mr-75"
              />
              <span class="font-weight-bold">{{$t('arl_codes.object.annullation_observations')}}</span><span class="text-danger">*</span>
            
              <validation-provider
                  #default="{ errors }"
                  :name="$t('arl_codes.object.annullation_observations')"
                  rules="required"
                >
                <b-form-textarea
                  id="annullation_observations"
                  v-model="elementData.annullation_observations"
                  maxlength="500"
                  max="500"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="d-flex justify-content-end">
          <b-button 
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            @click="hideModal()"
          >
          {{$t('actions.cancel')}}
          </b-button>
          <b-button
            variant="primary"
            @click="save()"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :disabled="invalid"
          >
          {{$t('actions.confirm')}}
          </b-button>
        </b-row>
      </validation-observer>
    </b-card-body>
  </b-card>

</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BButton, BAvatar, BRow, BCol, BBadge, BFormInput, BFormGroup, BForm, BFormSelect, BFormTextarea
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'


export default {
  components: {
    BFormTextarea, VueTimepicker, BCard, BButton, BRow, BCol, BAvatar, BBadge, BFormInput, BCardHeader, BCardBody, BFormGroup, BForm, BFormSelect, ValidationProvider, ValidationObserver 
  },
  props: {
    elementData: {
      type: Object,
      requiered: true
    }
  },
  data() {
    return {
      required
    }
  },
  methods: {
    hideModal() {
      this.$root.$emit('bv::hide::modal', 'modal-annullation')
    },
    save() {
      this.$root.$emit('bv::hide::modal', 'modal-annullation')
      this.$root.$emit('annull-entity')
    }
  }
}
</script>

<style>

</style>
